<template>
  <div class="connect-box">
    <div>
      <div >
        <el-select
          :multiple="false"
          clearable
          v-model="obj.feeCreateDate"
          @change="changeSelect"
          placeholder="请选择">
          <el-option v-for="(option, index) in dateData"
                     :key="index"
                     :label="option.dictValue"
                     :value="option.dictCode"></el-option>
        </el-select>
      </div>
      <span>+</span>
      <el-input @change="changeSelect" style="width: 100px;" placeholder="请输入" v-model="obj.delayDay"></el-input>
      <div style="width: 100px;">
        <el-select
          :multiple="false"
          clearable
          v-model="obj.delayType"
          @change="changeSelect"
          placeholder="请选择">
          <el-option v-for="(option, index) in units"
                     :key="index"
                     :label="option.dictValue"
                     :value="option.dictCode"></el-option>
        </el-select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'complex',
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      dateData: [],
      units: [],
    };
  },
  computed: {
    obj() {
      return this.value || {
        feeCreateDate: '',
        delayDay: '',
        // unit: '',
        delayType: '',
      };
    },
  },
  created() {
    this.getData('choice_date', 'dateData');
    this.getData('delay_type', 'units');
  },

  methods: {
    async getData(code, dataName) {
      const { result } = await this.$http.post('/mdm/mdmdictdata/list', { dictTypeCode: code });
      this[dataName] = result;
    },

    changeSelect(e) {
      this.$emit('input', { ...this.obj });
    },

  },
};
</script>

<style scoped lang="less">
.connect-box{
  display: flex;
span{
  margin: 0 10px;
}
>div{
  display: flex;
>div{
  margin: 0 5px;
&:first-child{
   margin-left: 0;
 }

}
}
}
</style>
